<template>
    <div>
        <b-card>
            <b-row>
                <b-col class="col-md-3">
                    <label for="host-input">{{ $t('FTPClientTest.host') }}</label>
                    <b-form-input
                        id="host-input"
                        v-model="host"
                        maxlength="50"
                    />
                </b-col>
                <b-col class="col-md-3">
                    <label for="port-input">{{ $t('FTPClientTest.port') }}</label>
                    <b-form-input
                        id="port-input"
                        v-model="port"
                        type="number"
                    />
                </b-col>
                <b-col class="col-md-3">
                    <label for="user-input">{{ $t('FTPClientTest.user') }}</label>
                    <b-form-input
                        id="user-input"
                        v-model="user"
                        maxlength="50"
                    />
                </b-col>
                <b-col class="col-md-3">
                    <label for="password-input">{{ $t('FTPClientTest.password') }}</label>
                    <b-form-input
                        id="password-input"
                        v-model="password"
                        maxlength="50"
                    />
                </b-col>
              </b-row>
              <b-row>
                <div class="custom-search d-flex justify-content-end mt-1" style="float: right; margin-left:auto">
                    <b-row>
                        <b-col>
                            <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="ftpClientTestUpload">
                                {{$t('others.ok_title')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
              </b-row>
        </b-card>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
        return {
        host: '',
        port: 0,
        user: '',
        password: ''
    }
  },
  methods: {
    ftpClientTestUpload(){
        // this.$ftpClient(this.host, this.port, this.user, this.password).then(result => {
        //     if(result.value){
        //         alert('İLKTURDABİTİRELİM', result.value)
        //     }
        // })
    }
  }
  }
  </script>